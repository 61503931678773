import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { HelmetProvider } from 'react-helmet-async';
import {
  GoogleReCaptchaProvider,
  withGoogleReCaptcha
} from "react-google-recaptcha-v3";


// material-ui
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './themes';
import * as actionTypes from './store/actions';

//routes
import { BrowserRouter } from 'react-router-dom';

//components
//import Navbar from './components/Navbar';
//import Footer from './components/Footer/Footer';
import Loader from './components/Loader/Loader';

import MainRoutes from './routes/MainRoutes';

import GetUniversalCookies from './components/GetUniversalCookies';

const MyApp = ({ googleReCaptchaProps }) => {
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  const getToken = useCallback(async () => {
    const _token = await googleReCaptchaProps.executeRecaptcha("buscatrastero");
    setToken(_token);
  }, [googleReCaptchaProps]);

/*
  useEffect(() => {
    localStorage.clear();
    
    (async () => {
      const response = await fetch(
        "/config/parameters.json"
      );
      const parsed = await response.json();
      dispatch({ type: actionTypes.SET_PARAMETERS, parameters: parsed });
      setIsLoading(false);
    })();
  }, []);


*/

  useEffect(() => {
    localStorage.clear();
    
    (async () => {
      const response = await fetch(
        "/config/parameters.json"
      );
      const parsed = await response.json();
      dispatch({ type: actionTypes.SET_PARAMETERS, parameters: parsed });
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) return <Loader />;
  
  return (
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme()}>
          <CssBaseline />
            <BrowserRouter>
{/*              <Navbar /> */}
              <MainRoutes/>
{/*              <Footer /> */}
            </BrowserRouter>
          <GetUniversalCookies />
        </ThemeProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  );
}

const MyAppGoogleReCaptcha = withGoogleReCaptcha(MyApp);

export default function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcvCCEqAAAAAMYHBXIc3_5N98VWkV9rAtB786wI">
      <MyAppGoogleReCaptcha />
    </GoogleReCaptchaProvider>
  );
}
